/*
 * This file is part of Solana Reference Stake Pool code.
 *
 * Copyright © 2023, mFactory GmbH
 *
 * Solana Reference Stake Pool is free software: you can redistribute it
 * and/or modify it under the terms of the GNU Affero General Public License
 * as published by the Free Software Foundation, either version 3
 * of the License, or (at your option) any later version.
 *
 * Solana Reference Stake Pool is distributed in the hope that it
 * will be useful, but WITHOUT ANY WARRANTY; without even the implied
 * warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
 * See the GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.
 * If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
 *
 * You can be released from the requirements of the Affero GNU General Public License
 * by purchasing a commercial license. The purchase of such a license is
 * mandatory as soon as you develop commercial activities using the
 * Solana Reference Stake Pool code without disclosing the source code of
 * your own applications.
 *
 * The developer of this program can be contacted at <info@mfactory.ch>.
 */

// apy
export const DEFAULT_APY = 0.077
export const APY_VALIDATOR_ID = '8BYmtxKY1LuvjesaMi1nkXcj6ghuq48iiGKq2jNpnrNY'

// intervals
export const EPOCH_RELOAD_INTERVAL = 60000
export const RATES_RELOAD_INTERVAL = 300000
export const DEFI_RELOAD_INTERVAL = 120000
export const POOL_RELOAD_INTERVAL = 30000
export const VALIDATORS_RELOAD_INTERVAL = 600000

// api
export const API_URL = import.meta.env.VITE_API_URL
export const API_COLLECTOR_URL = 'https://api.thevalidators.io'
export const API_HC_URL = 'https://api.hc.jpool.one'
// export const API_DIRECT_STAKE_URL = 'http://localhost:3000'
export const API_DIRECT_STAKE_URL = 'https://api2.jpool.one/direct-stake'

// holders club
export const JPOOL_ISSUER = 'CP11RdFL6SktY3ktKUaJsaxFQ3ky593BX2YQH47DqD7V'
export const HC_CREDENTIAL_NAME = 'Jpool Holder Credential'
export const HC_CREDENTIAL_SYMBOL = 'JPOOL-HC'

export const SMART_STAKE_LINK = 'https://app.jpool.one'
export const METEORA_LIQUIDITY_USDC_LINK = 'https://app.meteora.ag/pools/EXGiDU6yqQVBWesCCvKf4NzzAkE4EaRmuCLeU9zkLy7e'
export const METEORA_LIQUIDITY_SOL_LINK = 'https://app.meteora.ag/pools/ErzUZd2x5LgF4BwwU94akuujum8yx1L9FyaJ5in8wgD6'
export const SAVE_LIQUIDITY_LINK = 'https://save.finance/dashboard?pool=4UpD2fh7xH3VP9QQaXtsS1YY3bxzWhtfpks7FatyKvdY'
export const GALXE_LINK = 'https://app.galxe.com/quest/8pb3iheg2aSBAnnxDkYTPR/GCMBdtxuAP'
export const ALBUS_LINK = 'https://albus.finance'

// footer
export const DOCUMENTATION_LINK = 'https://docs.jpool.one/'
export const POOL_INFO_LINK = 'https://app.jpool.one/pool-info'

// misc
// export const HOW_TO_STAKE_URL = 'https://www.youtube.com/embed/KXPPGC8iOPM'
export const HOW_TO_STAKE_URL = 'https://www.youtube.com/channel/UCYG4ZRUBMgEPaBT1OrZKC-Q'

export const JSOL_LOGO
  = 'https://raw.githubusercontent.com/mfactory-lab/jpool-pub/main/assets/images/jsol.png'

export const BUILD_VERSION = import.meta.env.VERSION
export const PASSWORD_PROTECT = import.meta.env.VITE_PASSWORD_PROTECT
export const WITHDRAW_SOL_ACTIVE = true

export const JSCORE_MAX = {
  vaTotalScore: 11,
  // vaStakeConcentrationScore: 0,
  // vaPublishedInformationScore: 2,
  apyScore: 10,
  apy3Score: 10,
  apy10Score: 10,
  scAsnScore: 10,
  scDcScore: 10,
  scCountryScore: 10,
  scCityScore: 10,
  publishedInformationScore: 3,
  svtMemberScore: 2,
}

export const JSCORE_WEIGHT = {
  vaTotalScore: 1,
  apyScore: 0.33,
  apy3Score: 0.33,
  apy10Score: 0.33,
  scAsnScore: 0.25,
  scDcScore: 0.25,
  scCountryScore: 0.25,
  scCityScore: 0.25,
  svtMemberScore: 4,
}

export const JSCORE_MAX_TOTAL = Math.round(Object.keys(JSCORE_MAX).reduce((acc, cur) => acc + JSCORE_MAX[cur] * (JSCORE_WEIGHT[cur] ?? 0), 0))

// server concentration ratio
export const CONC_RATIO_LOW = 7
export const CONC_RATIO_MIDDLE = 10

export const STAKE_ACCOUNT_DATASIZE = 200
export const JSTAKING_VOTE = 'DPmsofVJ1UMRZADgwYAHotJnazMwohHzRHSoomL6Qcao'

export const FOCUS_NATIVE_STAKING_EVENT = Symbol('FOCUS_NATIVE_STAKING_EVENT')

export const DELEGATION_PERCENT = 5
// 0.0025 SOL  TODO: use getStakeMinimumDelegation?
export const MIN_NATIVE_STAKE = 2500000

/**
 * direct stake
 */
export const DIRECT_STAKE_PREFIX = 'direct:'
export const STRATEGY_STAKE_PREFIX = 'strategy:'
