/*
 * This file is part of Solana Reference Stake Pool code.
 *
 * Copyright © 2023, mFactory GmbH
 *
 * Solana Reference Stake Pool is free software: you can redistribute it
 * and/or modify it under the terms of the GNU Affero General Public License
 * as published by the Free Software Foundation, either version 3
 * of the License, or (at your option) any later version.
 *
 * Solana Reference Stake Pool is distributed in the hope that it
 * will be useful, but WITHOUT ANY WARRANTY; without even the implied
 * warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
 * See the GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.
 * If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
 *
 * You can be released from the requirements of the Affero GNU General Public License
 * by purchasing a commercial license. The purchase of such a license is
 * mandatory as soon as you develop commercial activities using the
 * Solana Reference Stake Pool code without disclosing the source code of
 * your own applications.
 *
 * The developer of this program can be contacted at <info@mfactory.ch>.
 */

import orcaIcon from '~/assets/img/defi/orca.svg'
import raydiumIcon from '~/assets/img/defi/raydium.svg'
import saberIcon from '~/assets/img/defi/saber.svg'
// import larixIcon from '~/assets/img/defi/larix.png'
// import atrixIcon from '~/assets/img/defi/atrix.svg'

type DefiLiquidityPair = {
  id: string
  icon?: string
  pair: string
  provider: string
  links?: {
    liquidity?: string
    swap?: string
    [key: string]: any
  }
  extra?: Record<string, any>
  description?: string
}

type DefiLendingPair = {
  id: string
  pair: string
  provider: string
  links?: Record<string, any>
  extra?: Record<string, any>
}

export const JSOL_MINT_ID = '7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn'
export const USDC_MINT_ID = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'
export const WRAP_SOL_MINT_ID = 'So11111111111111111111111111111111111111112'

// export const DEFI_LENDING_PROVIDERS = {
//   larix: {
//     name: 'LARIX LENDING PROTOCOL',
//     icon: larixIcon,
//   },
// }

export const DEFI_LENDING_PAIRS: Array<DefiLendingPair> = [
  {
    id: 'LARIX-JSOL',
    pair: 'JSOL',
    provider: 'larix',
    links: {
      supplyBorrow: 'https://projectlarix.com/',
    },
    extra: {
      mintId: JSOL_MINT_ID,
    },
  },
]

export const DEFI_LIQUIDITY_PROVIDERS = {
  raydium: {
    name: 'RAYDIUM LIQUIDITY POOL',
    icon: raydiumIcon,
  },
  orca: {
    name: 'ORCA LIQUIDITY POOL',
    icon: orcaIcon,
  },
  saber: {
    name: 'SABER LIQUIDITY POOL',
    icon: saberIcon,
  },
  // atrix: {
  //   name: 'ATRIX LIQUIDITY POOL',
  //   icon: atrixIcon,
  // },
}

export const DEFI_LIQUIDITY_PAIRS: Array<DefiLiquidityPair> = [
  {
    id: 'RAY-JSOL-SOL',
    pair: 'JSOL-SOL',
    provider: 'raydium',
    links: {
      liquidity:
        'https://raydium.io/liquidity/increase/?mode=add&pool_id=D8pasgJWjP9wy39fzeD8BUjQMvYCZxABzPcnuoDSLHBB',
      swap: 'https://raydium.io/swap/?inputCurrency=7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn&outputCurrency=sol&fixed=in',
    },
  },
  {
    id: 'RAY-JSOL-USDC',
    pair: 'JSOL-USDC',
    provider: 'raydium',
    links: {
      liquidity:
        'https://raydium.io/liquidity/increase/?mode=add&pool_id=7e8GrkwsRm5sS5UaKobLJUNu9esmrzg37dqX6aQyuver',
      swap: 'https://raydium.io/swap/?inputMint=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&outputMint=7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn',
    },
  },
  {
    id: 'SABER-JSOL-SOL',
    pair: 'JSOL-SOL',
    provider: 'saber',
    links: {
      liquidity: 'https://app.saberdao.io/pools/jsol/',
      swap: '',
    },
  },
  {
    id: 'SABER-JSOL-mSOL',
    pair: 'JSOL-mSOL',
    provider: 'saber',
    links: {
      liquidity: 'https://app.saberdao.io/pools/msol_jsol/',
      swap: '',
    },
    extra: {
      pair: 'mSOL-JSOL',
    },
  },
  {
    id: 'SABER-JSOL-scnSOL',
    pair: 'JSOL-INF',
    provider: 'saber',
    links: {
      liquidity: 'https://app.saberdao.io/pools/scn_jsol/',
      swap: '',
    },
    extra: {
      pair: 'INF-JSOL',
    },
  },
  {
    id: 'ORCA-JSOL-USDC',
    pair: 'JSOL-USDC',
    provider: 'orca',
    links: {
      liquidity: 'https://www.orca.so/pools?tokens=7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn&tokens=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
      swap: 'https://www.orca.so/?tokenIn=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&tokenOut=7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn',
    },
  },
  // {
  //   id: 'ORCA-JSOL-USDC-WP',
  //   pair: 'JSOL-USDC',
  //   provider: 'orca',
  //   links: {
  //     liquidity: 'https://www.orca.so/whirlpools',
  //     swap: 'https://www.orca.so/?input=JSOL&output=USDC',
  //   },
  //   extra: {
  //     title: 'ORCA LIQUIDITY WHIRLPOOL',
  //     tokenMintA: JSOL_MINT_ID,
  //     tokenMintB: USDC_MINT_ID,
  //   },
  // },
  {
    id: 'ORCA-JSOL-SOL-WP',
    pair: 'JSOL-SOL',
    provider: 'orca',
    links: {
      liquidity: 'https://www.orca.so/pools?tokens=7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn&tokens=So11111111111111111111111111111111111111112',
      swap: 'https://www.orca.so/?tokenIn=So11111111111111111111111111111111111111112&tokenOut=7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn',
    },
    extra: {
      title: 'ORCA LIQUIDITY',
      // title: 'ORCA LIQUIDITY WHIRLPOOL',
      tokenMintA: WRAP_SOL_MINT_ID,
      tokenMintB: JSOL_MINT_ID,
    },
  },
  // {
  //   id: 'ATRIX-JSOL-USDC',
  //   pair: 'JSOL-USDC',
  //   provider: 'atrix',
  //   links: {
  //     liquidity: 'https://app.atrix.finance/#/pools/D6QpZ4DTm9xxfRtShJL3t1eZ2ezBjV2M9Mif86zHoZSd/deposit',
  //     swap: 'https://app.atrix.finance/#/swap?to=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&from=7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn',
  //   },
  //   extra: {
  //     poolKey: 'D6QpZ4DTm9xxfRtShJL3t1eZ2ezBjV2M9Mif86zHoZSd',
  //   },
  // },
]
